import React, { startTransition, useState } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { baseUrl } from "../App";
import axios from "axios";

const Modalar = (props) => {
  const navigate = useNavigate();

  const [signupModal, setSignupmodal] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [signinModal, setSigninmodal] = useState(false);

  const closeModal = () => {
    setSignupmodal(false);
  };

  const closeSignupModal = () => {
    setSigninmodal(true);
  };

  // console.log({ signinModal, signupModal });

  // const {
  //   formData,
  //   handleChange,
  //   handleCheckBox,
  //   onSubmit,
  //   isLoading,
  //   closeModal,
  // } = props;
  
  const [visible, setVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const toggleVisibility = () => setVisible(!visible);
  function handleCheckBox() {
    setIsChecked(!isChecked);
  }

  // signIn
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // signup
  const onSubmit = async (e) => {
    if (!isChecked) {
      toast.error("Please agree to the Terms & Conditions before submitting");
      return;
    } 
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/register`, {
        email: formData.email,
        password: formData.password,
        website: formData.website,
        role: "client",
      });
      // await handleMail(formData.email)
      localStorage.setItem("user_info", JSON.stringify(formData));
      toast.success(response?.data?.message);
      navigate("/otp");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setIsLoading(false);
      // navigate('/dashboard');
    }
  };
  // sign in
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email || !formData.password) {
      toast.error("Please provide both email and password.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/login`, {
        username: formData.email,
        password: formData.password,
        withCredentials: true,
      });
      !response?.data?.onboarding
        ? navigate("/onBoarding")
        : navigate("/dashboard");
      toast.success(response?.data?.message);
      // localStorage.setItem("isAuth", "true");
      localStorage.setItem("user_info", JSON.stringify(formData));
    } catch (error) {
      if (error?.response?.data === "Unauthorized") {
        toast.error("Email or Password incorrect");
        return;
      }
      toast.error(error?.response?.data?.message || "something went wrong");
      console.log(formData);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className=" bg-gray-300 h-screen w-screen fixed">
      <h1 className=" font-bold text-2xl ml-28 mt-16 ">
        {" "}
        <span className=" text-3xl text-[#00337C]">B</span>
        <span className="text-[#00337C]">offin</span>
        <span className="bg-primary rounded p-1 text-white">Blocks</span>
      </h1>
      {signupModal && (
        <div
          className="xl:w-[50%] xl:h-[80%] lg:w-[100%] md:w-[100%] sm:w-[100%] xl:ml-[.5rem] lg:ml-[2rem] md:ml-[1rem] sm:ml-[-7.5rem] flex justify-center items-center py-12 md:py-0 mt-1 bg-slate-100 bg-opacity-35 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] backdrop-blur-3xl "
          // onClick={() => closeModal()}
        >
          <button
            type="button"
            className="absolute top-3 right-3 bg-transparent p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 rounded"
            aria-label="Close"
            onClick={() => {
              setSignupmodal(false);
              setShowButton(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div className=" flex flex-col justify-center">
            <div className=" flex justify-between">
              <h1 className="text-center text-3xl font-bold ml-14">
                Create Free Account
              </h1>
            </div>
            <p className="text-center mt-4">No credit card required</p>
            <div className="w-full mt-5">
              <input
                className="w-full rounded-md border-2 border-[#C5CDD8] py-5 px-7 mt-5"
                placeholder="Email address"
                type="email"
                name="email"
                value={formData?.email}
                onChange={handleChange}
              />
              <div className="relative">
                <input
                  className="w-full rounded-md border-2 border-[#C5CDD8] py-5 px-7 mt-5"
                  placeholder="Password"
                  type={visible ? "text" : "password"}
                  name="password"
                  value={formData?.password}
                  onChange={handleChange}
                />
                <span
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer text-gray-500 mt-2"
                  onClick={toggleVisibility}
                >
                  {visible ? (
                    <FaEyeSlash className="h-6 w-6" />
                  ) : (
                    <FaEye className="h-6 w-6" />
                  )}
                </span>
              </div>
              <input
                className="w-full rounded-md border-2 border-[#C5CDD8] py-5 px-7 mt-5"
                placeholder="Website"
                type="text"
                name="website"
                value={formData?.website}
                onChange={handleChange}
              />
            </div>
            <div className="flex items-center mt-5">
              <input
                type="checkbox"
                className="w-4 h-4 rounded-2xl"
                onChange={handleCheckBox}
              />
              <label className="ml-3">
                I agree to the{" "}
                <span className="text-primary font-semibold">
                  Terms & Conditions
                </span>{" "}
                and{" "}
                <span className="text-primary font-semibold">
                  Privacy Policy
                </span>
              </label>
            </div>
            <button
              onClick={onSubmit}
              className="w-full bg-primary rounded-md text-white font-semibold p-5 mt-5"
              disabled={isLoading}
            >
              {isLoading ? "Getting Started..." : "Get Started For Free"}
            </button>
            <div className="mt-5">
              Do have an account?
              <span
                onClick={() => {
                  setSigninmodal(true);
                  setSignupmodal(false);
                }}
                // onClick={() => startTransition(() => navigate("/signin"))}
                className="font-bold text-primary cursor-pointer ml-1 "
              >
                Sign In
              </span>
            </div>
          </div>
        </div>
      )}
      {signinModal && (
        <>
          <div className=" xl:w-[50%] xl:h-[80%] lg:w-[100%] md:w-[100%] sm:w-[100%] xl:ml-[.5rem] lg:ml-[2rem] md:ml-[1rem] sm:ml-[-7.5rem] flex justify-center items-center py-12 md:py-0 mt-1 bg-slate-100 bg-opacity-35 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]   ">
            <button
              type="button"
              className="absolute top-3 right-3 bg-transparent p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 rounded"
              aria-label="Close"
              onClick={() => {
                setSigninmodal(false);
                setShowButton(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className="xl:w-[45%] w-[70%] flex flex-col mt-10 justify-center mx-auto ">
              <h1 className="text-center text-3xl font-bold">Sign In</h1>
              <div className="w-full mt-7">
                <input
                  className="w-full rounded-md border-2 border-[#C5CDD8] py-5 px-7 mt-5"
                  placeholder="Email address"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <div className="relative">
                  <input
                    className="w-full rounded-md border-2 border-[#C5CDD8] py-5 px-7 mt-5"
                    placeholder="Password"
                    type={visible ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <span
                    className="absolute top-1/2 right-8 transform -translate-y-1/2 cursor-pointer text-gray-500 mt-3"
                    onClick={toggleVisibility}
                  >
                    {visible ? (
                      <FaEyeSlash className="h-6 w-6" />
                    ) : (
                      <FaEye className="h-6 w-6" />
                    )}
                  </span>
                </div>
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="w-full bg-primary rounded-md text-white font-semibold p-5 mt-5"
                disabled={isLoading}
              >
                {isLoading ? "loading..." : "Sign In"}
              </button>
              <div className="flex justify-between">
                <div className="mt-5">
                  Don't have account?
                  <span
                    onClick={() => {
                      setSignupmodal(true);
                      setSigninmodal(false);
                    }}
                    className="font-bold text-primary cursor-pointer ml-1"
                  >
                    Sign Up
                  </span>
                </div>
                <div className="mt-5">
                  <span
                    onClick={() => startTransition(() => navigate("/forget"))}
                    className="font-bold text-primary cursor-pointer ml-1"
                  >
                    Forgot password
                  </span>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </>
      )}

      {showButton && (
        <div
          className="w-full md:w-1/2 flex flex-col justify-center items-center relative pb-20 pt-32 md:pt-16 lg:pt-0 xl:ml-[24rem] xl:mt-[8rem] "
          // onClick={() => closeModal(true)
          // }
        >
          <div className="w-[80%] md:w-[63%] mt-5">
            <h1 className="leading-[1.3] text-2xl font-semibold">
              Have Better Conversations with Customers, Build Trust, and
              actively drive more Sales.
            </h1>
            <p className="text-[#6F7277] leading-[1.3] text-sm pr-10 my-5">
              Maximize KPI results with an Advanced human-level AI requiring
              zero Human Intervention
            </p>
            <div className="w-[90%] flex flex-col">
              <div className="flex">
                <div className="mt-0.5">
                  <FaCircleCheck size={20} className="text-primary" />
                </div>
                <p className="leading-[1.3] text-sm pl-3">
                  Operates as an experienced Salesperson manager for your
                  Business with a primary goal of Guiding Customers through the
                  Buying Journey 24/7 to improve sales.
                </p>
              </div>
              <div className="flex pt-5">
                <div className="mt-0.5">
                  <FaCircleCheck size={20} className="text-primary" />
                </div>
                <p className="leading-[1.3] text-sm pl-3">
                  Solve 99% of Customer Queries while personalizing every
                  interactions.
                </p>
              </div>
              <div className="flex pt-5">
                <div className="mt-0.5">
                  <FaCircleCheck size={20} className="text-primary" />
                </div>
                <p className="leading-[1.3] text-sm pl-3">
                  Eliminates the need for a Human Agent and cancels the Cost and
                  Wasted Time Associated with hiring.
                </p>
              </div>
            </div>
          </div>

          <div className="flex gap-16 justify-between mt-6 mr-10">
            <button
              onClick={() => {
                setSignupmodal(true);
                setSigninmodal(false);
                setShowButton(false);
              }}
              className="mt-5 border w-32 bg-primary rounded-md text-white font-semibold
              p-5 "
            >
              Sign Up
            </button>
            <button
              onClick={() => {
                setSignupmodal(false);
                setShowButton(false);
                setSigninmodal(true);
              }}
              className="w-32 border bg-primary rounded-md text-white font-semibold
              p-5 mt-5"
            >
              Sign In
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modalar;

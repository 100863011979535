import React, { useState, useEffect, useRef } from 'react';
import userIcon from '../assets/logo/user.svg';
import galleryIcon from '../assets/logo/gallery.svg';
import paperClipIcon from '../assets/logo/paperclip.svg';
import emojiIcon from '../assets/logo/emoji.svg';
import sendArrowIcon from '../assets/logo/sendArrow.svg';
import { IoIosArrowDown } from 'react-icons/io';
import { RxCrossCircled } from 'react-icons/rx';
import { baseUrl } from '../App';
import chatbotIcon from '../assets/logo/chatbot.svg';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function Contact_bot() {
  const { email, clientId } = useParams();
  console.log({ email, clientId }, 'emailand value');
  const scrollViewRef = useRef();
  const [activeTab, setActiveTab] = useState(1);
  const [titleName, setTitleName] = useState('');
  const [themeColor, setThemeColor] = useState('#000');
  const [userAvatar, setuserAvatar] = useState('');
  const [fontFamily, setfontFamily] = useState('font-sans');
  let selectedFile = null;
  const [message, setMessage] = useState('');
  const [minimized, setMinimized] = useState(false);
  const [allMessages, setAllMessages] = useState([
    {
      sender: 'AI',
      content: 'I am Emilly Chatbot!',
      currentTime: new Date().toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }),
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
  //   const clientProfileInfo = JSON.parse(
  //     localStorage.getItem('clientProfileDetails')
  //   );

  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  const toggleMinimize = (e) => {
    setMinimized(!minimized);
  };

  // initialize chat
  async function initialize() {
    try {
      const userData = {
        clientId: clientId,
        // clientId is widget id
        name: `support${Date.now()}`,
        email: `support${Date.now()}`,
      };

      const res = await fetch(`${baseUrl}/chat/initializechat`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
      });
      const data = await res.json();
      localStorage.setItem('chatId', JSON.stringify(data.chatId));
    } catch (error) {
      console.log('Error while initailizing chat', error);
    }
  }

  async function addMessageToChat(sender, content, clientId, responseTime) {
    let currentTime = new Date().toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    const newMessage = { sender, content, currentTime };
    setAllMessages((prev) => {
      return [...prev, newMessage];
    });
    console.log(responseTime, 'response Time');
    try {
      const res = await fetch(`${baseUrl}/chat/messages`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sender,
          content,
          clientId,
          //   chatId: JSON.parse(localStorage.getItem('chatId')),
          responseTime,
        }),
      });
    } catch (error) {
      console.log('Error while saving message', error);
    }
  }

  // Asking query
  async function askAnswer(e) {
    e.preventDefault();
    setMessage('');
    setIsTyping(false);
    await addMessageToChat('user', message, clientId);
    const queryAsk = Date.now();
    const response = await fetch(`${baseUrl}/chat/ask`, {
      method: 'POST',
      headers: {
        authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3RAYWRtaW4uY29tIiwiaWF0IjoxNjg4NDgwNTE2fQ.G_-oxFG7dZHhI9cwvJeEKJsaiqgJYxSOwoOkhOyybE8',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: message,
        collectionName: clientId,
      }),

      // collectionName is widget Id
    });
    const ai_Answer = await response.json();
    setIsTyping(true);
    const answerRecieved = Date.now();
    console.log(answerRecieved - queryAsk, 'response time');
    await addMessageToChat(
      'AI',
      ai_Answer.answer,
      clientId,
      answerRecieved - queryAsk
    );
  }

  //   async function clientDetails() {
  //     const clientInfo = await axios.get(`${baseUrl}/auth/loggedInPersonInfo`);
  //     localStorage.setItem(
  //       'clientProfileDetails',
  //       JSON.stringify({
  //         clientId: clientInfo.data?.clientInfo?._id,
  //         username: clientInfo.data?.clientInfo?.username,
  //         emailId: clientInfo.data?.clientInfo?.email,
  //       })
  //     );
  //   }
  const fetchChatBotSetting = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/auth/client/chatbotsetting`,
        {
          email,
        }
      );
      const chatBotSettings = response.data;
      setTitleName(response.data?.setting?.styling?.title);
      setThemeColor(response.data?.setting?.styling?.themeColor);
      setuserAvatar(response.data?.setting?.styling?.avatar);
      setfontFamily(response.data?.setting?.styling?.fontFamily);
    } catch (error) {
      console.log('Error fetching chatbot settings:', error);
    }
  };

  const tabs = [
    'AI Propmting Interface',
    'Add Content/Upload Documents',
    'FAQ',
    'AI Model Interaction/ Chat Simulation Live',
  ];

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
    // clientDetails();
    fetchChatBotSetting();
  }, []);
  useEffect(() => {
    // if (!JSON.parse(localStorage.getItem('chatId'))) {
    initialize();
    // }
  }, []);

  return (
    <div className='w-full flex '>
      <div className='w-full h-[90vh] relative py-5 pl-8 pr-20'>
        <div className='w-full rounded-lg mt-5 pt-5 pb-2.5'>
          <div className='relative flex justify-center items-center mt-8 '>
            <div className='justify-center md:justify-center h-[80vh] pb-32 w-full  flex py-16 md:py-0'>
              {
                <div
                  className={`'h-[80%]'
                   w-[75%] md:w-[100%] lg:w-[100%] xl:w-[100%] 2xl:w-[85%] flex flex-col justify-between relative rounded-md shadow-lg bg-white overflow-hidden `}
                >
                  <div
                    style={{ background: `${themeColor}` }}
                    className='w-full flex justify-between rounded-lg shadow-xl px-6 py-4'
                  >
                    <div className='flex items-center text-white'>
                      <img
                        src={
                          selectedFile
                            ? URL.createObjectURL(selectedFile)
                            : userAvatar
                        }
                        alt='logo'
                        className='h-14 w-14 rounded-full'
                      />
                      <div className='ml-3'>
                        <h1 className='text-sm'>{titleName}</h1>
                        <p className='text-xs mt-1'>Active Now</p>
                      </div>
                    </div>
                    <div className='flex items-center text-white'>
                      {/* <IoIosArrowDown
                        onClick={toggleMinimize}
                        size={20}
                        className='mr-4 cursor-pointer'
                      /> */}
                      {/* <RxCrossCircled size={20} /> */}
                    </div>
                  </div>

                  <div
                    ref={scrollViewRef}
                    className='w-full h-[74%] shadow-md overflow-y-auto pb-5 px-5 '
                  >
                    {allMessages.map((message, index) => (
                      <div key={index}>
                        {message.sender === 'user' ? (
                          <div>
                            <div className='w-[85%] ml-auto mt-5 '>
                              <div
                                style={{ background: `${themeColor}` }}
                                className='px-3 py-3 rounded-lg'
                              >
                                <h1 className={`text-white ${fontFamily}`}>
                                  {message.content}
                                </h1>
                              </div>
                              <p className='text-right text-sm text-[#738493] mt-1.5'>
                                Sent {message.currentTime}
                              </p>
                            </div>
                            {!isTyping && index === allMessages.length - 1 && (
                              <div className='flex items-start ml-3 mt-5'>
                                <img
                                  src={
                                    selectedFile
                                      ? URL.createObjectURL(selectedFile)
                                      : userAvatar
                                  }
                                  alt='logo'
                                  className='h-14 w-14 rounded-full'
                                />
                                <div className='bg-[#F4F6F8] w-full rounded-lg px-3 py-3 ml-2'>
                                  <h1
                                    className={`text-[#454F5B] ${fontFamily}`}
                                  >
                                   Support Typing...
                                  </h1>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className='flex items-start ml-3 mt-5'>
                            <img
                              src={
                                selectedFile
                                  ? URL.createObjectURL(selectedFile)
                                  : userAvatar
                              }
                              alt='logo'
                              className='h-14 w-14 rounded-full'
                            />
                            <div>
                              <div className='bg-[#F4F6F8] w-full rounded-lg px-3 py-3 ml-2'>
                                <h1 className={`text-[#454F5B] ${fontFamily}`}>
                                  {message.content}
                                </h1>
                              </div>
                              <p className='text-sm text-[#738493] mt-1.5 ml-2'>
                                Recieved {message.currentTime}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <form onSubmit={askAnswer}>
                    <div className=' w-full flex rounded-md shadow-inner bg-[#fff] px-6 py-4'>
                      <button>
                        <img src={galleryIcon} alt='logo' className='w-8' />
                      </button>
                      {/* <button className='mx-3'>
                <img src={paperClipIcon} alt='logo' className='w-8' />
              </button> */}
                      <div className='flex bg-[#F4F6F8] w-full rounded-full pl-4 py-2 ml-2'>
                        <input
                          className='bg-[#F4F6F8] w-full outline-none text-sm'
                          placeholder='Enter message...'
                          type='text'
                          value={message}
                          onChange={handleChange}
                        />
                        <button className='mx-3'>
                          <img src={emojiIcon} alt='logo' className='w-7' />
                        </button>
                      </div>
                      <button className='ml-5'>
                        <img src={sendArrowIcon} alt='logo' className='w-9' />
                      </button>
                    </div>
                  </form>
                  <h2 className='text-center font-extrabold text-bold text-sm mb-1'>
                    Powered by BoffinBlocks AI
                  </h2>
                </div>
              }

              {/* <img
                src={chatbotIcon}
                onClick={() => setMinimized(!minimized)}
                alt='logo'
                className='-bottom-16 right-[34%] absolute z-50 h-14 w-14'
              /> */}
            </div>
            {/* <img
                src={chatbotIcon}
                alt='logo'
                className='-bottom-16 right-[34%] absolute z-50 h-14 w-14'
              /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { lazy, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { authCheck } from "../services/authServices";
import { useNavigate } from "react-router-dom";
import Contact_bot from "../pages/Contact_bot";
import Modalar from "../components/Modalar";
// import SignUp from '../pages/SignUp';

const Home = lazy(() => import("../pages/Home"));
const OnBoarding = lazy(() => import("../pages/OnBoarding"));
const Tutorials = lazy(() => import("../pages/Tutorials"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Analytics = lazy(() => import("../pages/Analytics"));
const SignIn = lazy(() => import("../pages/SignIn"));
const CustomerManagement = lazy(() => import("../pages/CustomerMangement"));
const ChatHistory = lazy(() => import("../pages/ChatHistory"));
const TrainAi = lazy(() => import("../pages/TrainAi"));
const Settings = lazy(() => import("../pages/Settings"));
const Chat = lazy(() => import("../pages/Chat"));
const SignUp = lazy(() => import("../pages/SignUp"));
const Forget = lazy(() => import("../pages/Forget"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const Otp = lazy(() => import("../pages/Otp"));
const PersonalProfile = lazy(() => import("../pages/PersonalProfile"));
const Team = lazy(() => import("../pages/Team"));
const Billings = lazy(() => import("../pages/Billings"));
const CustomerRatings = lazy(() => import("../pages/CustomerRatings"));
const NotificationPage = lazy(() => import("../pages/NotificationPage"));
const ContactUs = lazy(() => import("../pages/ContactUs"));

function AllRoutes() {
  const [isAuthen, setIsAuthen] = useState(!!localStorage.getItem("isAuth"));
  const [onboardingChecked, setOnboardingChecked] = useState(false);
  const navigate = useNavigate();
  // const user = localStorage.getItem('user_info');
  useEffect(() => {
    authCheck()
      .then((res) => {
        if (res.data.isAuthenticated) {
          setIsAuthen(true);
          if (!localStorage.getItem("isAuth")) {
            res.data?.user?.onboarding
              ? navigate("/dashboard")
              : navigate("/onBoarding");
            localStorage.setItem("onboarding", res.data?.user?.onboarding);
          }
          localStorage.setItem("isAuth", "true");
        } else {
          navigate("/signin");
          setIsAuthen(false);
          localStorage.removeItem("isAuth");
        }
      })
      .catch(() => {
        setIsAuthen(false);
        localStorage.removeItem("isAuth");
      })
      .finally(() => {
        setOnboardingChecked(true);
      });
  }, [isAuthen, navigate]);
  if (!onboardingChecked) {
    return null;
  }
  return (
    <Routes>
      <Route path="/" element={<Modalar />} />
      <Route
        // path="/signup"
        path="/"
        element={
          isAuthen && localStorage.getItem("onboarding") ? (
            <OnBoarding />
          ) : (
            <Modalar />
          )
        }
      />
      {/* i have replace /signin with /  */}
      {/* <Route path='/modal'
      element ={<Modal/>}
      /> */}
      <Route
        path="/onBoarding"
        element={isAuthen ? <OnBoarding /> : <Navigate to={"/"} />}
      />
      <Route
        path="/tutorials"
        element={isAuthen ? <Tutorials /> : <Navigate to={"/"} />}
      />
      <Route
        path="/analytics"
        element={isAuthen ? <Analytics /> : <Navigate to={"/"} />}
      />
      <Route
        path="/notifications"
        element={isAuthen ? <NotificationPage /> : <Navigate to={"/"} />}
      />
      <Route
        path="/customers"
        element={isAuthen ? <CustomerManagement /> : <Navigate to={"/"} />}
      />
      <Route
        path="/dashboard"
        element={isAuthen ? <Dashboard /> : <Navigate to={"/"} />}
      />
      {/* <Route path='/signin' Component={SignIn} /> */}
      <Route path="/" Component={SignUp} />

      <Route
        path="/trainAi"
        element={isAuthen ? <TrainAi /> : <Navigate to={"/"} />}
      />
      <Route path="/contactbot/:email/:clientId" element={<Contact_bot />} />
      <Route
        path="/chatHistory"
        element={isAuthen ? <ChatHistory /> : <Navigate to={"/"} />}
      />
      <Route
        path="/chatHistory/:id"
        element={isAuthen ? <Chat /> : <Navigate to={"/"} />}
      />
      <Route
        path="/settings"
        element={isAuthen ? <Settings /> : <Navigate to={"/"} />}
      />
      <Route
        path="signin"
        element={!isAuthen ? <SignIn /> : <Navigate to={"/dashboard"} />}
      />
      <Route
        path="/forget"
        element={!isAuthen ? <Forget /> : <Navigate to={"/"} />}
      />
      <Route
        path="/resetpassword/:email"
        element={!isAuthen ? <ResetPassword /> : <Navigate to={"/"} />}
      />
      <Route
        path="/otp"
        element={!isAuthen ? <Otp /> : <Navigate to={"/"} />}
      />
      <Route
        path="/profile"
        element={isAuthen ? <PersonalProfile /> : <Navigate to={"/"} />}
      />
      <Route
        path="/team"
        element={isAuthen ? <Team /> : <Navigate to={"/"} />}
      />
      <Route
        path="/billings"
        element={isAuthen ? <Billings /> : <Navigate to={"/"} />}
      />
      <Route
        path="/customersrating"
        element={isAuthen ? <CustomerRatings /> : <Navigate to={"/"} />}
      />
    </Routes>
  );
}

export default AllRoutes;

import { BrowserRouter } from 'react-router-dom';
import AllRoutes from './routes/AllRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <AllRoutes />
      <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
    </BrowserRouter>
  );
}

export const baseUrl = 'https://aichatbot.boffinblocks.com/api';
export const baseUrl2 = 'https://aichatbot.boffinblocks.com'
export default App;

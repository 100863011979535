import axios from "axios";
import { baseUrl } from "../App";
axios.defaults.withCredentials = true;

export const signIn = async()=>{
    return axios.post(`${baseUrl}`)
}
export const authCheck = async()=>{
    return axios.get(`${baseUrl}/authenticated`,{withCredentials: true,})
}
export const logOut = async()=>{
    return axios.post(`${baseUrl}/auth/logout`)
}
